<template>
  <div>
    <div class="centerbox">

        <van-nav-bar
            title="现货"
            safe-area-inset-top
            fixed
            placeholder
            :border="false"
        ></van-nav-bar>
       <div class="boxShadow">
            <!-- 实时金价 -->
          <div class="yellowbox">
              <div class="flex-around tab_div ">
                  <div
                     :class="thanIndex == index ? 'active' : ''"
                     v-for="(items, index) in productlist"
                     :key="index"
                     @click="thanTab(index, items.code)"
                  >
                      {{ items.name }}
                 </div>
              </div>
          </div>
          <div class="boxgoldprice">
      <div class="flex-between nowprice" v-if="tabover">
        <div>
          <div
            class="pricebox"
            :class="redup ? 'redfont' : 'greenfont'"
            style="text-align: left;"
          >
            {{ pricenowbox.price || "-" }}
            <i class="arrowboxImg" v-if="redup">
              <img src="@/assets/images/icon_trend_up@2x.png" alt=""/>
            </i>
            <i class="arrowboxImg" v-if="greendown"
              ><img src="@/assets/images/icon_down.png" alt=""
            /></i>
          </div>
          <div class="timebox" style="text-align: left;">{{ timebox }}</div>
          <!-- {{pricenowbox.date || '-'}} -->
        </div>
        <div class="flex flexbox">
          <div>
            <div class="price_marb price_marl tl">
              今开: {{ pricenowbox.todayPrice || "0" }}
            </div>
            <div class="price_marb price_marl">
              昨开: {{ pricenowbox.yesterdayPrice || "0" }}
            </div>
          </div>
<!--          <div>
            <div class="price_marb tl">
              最高:  <span style="color:#CC0E0F"> {{ pricenowbox.maxPrice || "-" }}</span>
            </div>
            <div class="price_marb">最低: <span style="color:#02790D">{{ pricenowbox.minPrice || "-" }}</span></div>
          </div>-->
        </div>
       </div>
      <!-- 菊花加载 -->
       <div v-if="!tabover" class="loadigboxdetals">
        <van-loading color="#FF2D2E" size="38px" />
       </div>
       <div class="priceboxcenter">
        <div v-if="tabover">
          <div v-if="havedata">
            <child
              :datalist="huangjin9999"
              :secondprice="emitdata"
              :data_box="timelistcenter"
              :ydata="ydataarr"
            ></child>
          </div>
        </div>
       </div>
      <!-- <div class="priceboxcenter">
             <div v-if="havedatasecond">
                  <child :datalist="huangjin9999"></child>
             </div>
           </div> -->
      <!--立刻卖金 -->
      <!--  <router-link :to="{name:'sellgold',query:{price:this.pricenowbox.price }}"></router-link> -->
      <!-- <div class="btnsell" @click="sellgoldF(pricenowbox.price)">立即卖金</div> -->
        </div>
        </div>
      <div class="newPricing">
        <div class="newPricingTitle">类目</div>
        <div class="flex_between newPricingContent">
          <div>
            <div class="newPricingBoxCommon flex_between" @click="buySellLink('sellgold')">
              <div>
                <h3>卖料</h3>
                <h4>实时金价 专业回购</h4>
              </div>
              <img src="~@/assets/images/icon_pricing_sell_small.png" alt="">
            </div>
            <div class="newPricingBoxCommon flex_between" @click="buySellLink('Buygold')">
              <div>
                <h3>买料</h3>
                <h4>实时金价 专业销售</h4>
              </div>
              <img src="~@/assets/images/icon_pricing_sell_small.png" alt="">
            </div>
          </div>
          <div class="newPricingBoxShop" @click="buySellLink('shopping')">
            <div>
              <h3>商城</h3>
              <h4>实时金价 专业销售</h4>
            </div>
            <img src="~@/assets/images/icon_pricing_shopping.png" alt="">
          </div>
        </div>
      </div>
<!--        <div class="boxShadow flex_between" key="sell" @click="buySellLink('sellgold')">
            <div class="sell">
              <h3 >卖料</h3>
              <h4>实时金价 专业回购</h4>
            </div>
          <img src="~@/assets/images/icon_pricing_sell.png" alt="">
        </div>
        <div class="boxShadow flex_between" key="buy" @click="buySellLink('Buygold')">
            <div class="buy">
              <h3>买料</h3>
              <h4>实时金价 专业销售</h4>
            </div>
          <img src="~@/assets/images/icon_pricing_buy.png" alt="">
        </div>
      <div class="boxShadow flex_between" key="shopping" @click="buySellLink('shopping')">
        <div class="buy">
          <h3>商城</h3>
          <h4>实时金价 专业销售</h4>
        </div>
        <img src="~@/assets/images/icon_pricing_shopping.png" alt="">
      </div>-->
           <!-- 回收购流程
        <div class="boxShadow">
            <div class="manual" @click="onlineRecovery">
            </div>
        </div> -->

        <van-dialog v-model="ceringModel">
          <div class="title">提示</div>
          <div class="centerbox_dialog">
            停盘中，暂时无法进行交易，开盘时间周一至周五早上9:35-02:00
          </div>
          <div class="gocer" @click="ceringModel = false">确定</div>
        </van-dialog>
        <van-dialog v-model="nocer">
          <div class="title">{{tipTitle}}</div>
          <div class="centerbox_dialog">{{ infodetials }}</div>
          <div  class="flex flex-aroundbox">
            <div class="nogocer" @click="nocer = false">取消</div>
            <div class="nogocercolor">
            <div @click="goFace">去认证</div>
            </div>
          </div>
        </van-dialog>
      <van-dialog v-model="disableModel">
        <div class="title">提示</div>
        <div class="centerbox_dialog">
          系统升级，请联系相关业务经理
        </div>
        <div class="gocer" @click="disableModel = false">确定</div>
      </van-dialog>
    </div>
  </div>
</template>
<script>
import "vant/lib/popup/style";
import "vant/lib/area/style";
import child from "./hcharts.vue";
import Stomp from "stompjs";
// 保险单列表
import * as PriceLineChart from "@/utils/api";
// queryCurrentPriceLineChart
export default {
  data() {
    return {
      // 是否开盘
      trade_: false,
      infodetials: "",
      tipTitle:'',
      nocer: false,
      ceringModel: false,
      disableModel: false, // 系统升级弹窗
      // Y轴刻度尺
      ydataarr: [],
      // tab切换后完成
      tabover: false,
      // 当前的坐标
      thanIndex: 0,
      // 是否已经点击
      isClick: false,
      productlist: [
        { name: "黄金", code: "huangjin9999" },
        { name: "铂金", code: "bojin9996" },
        // { name: "钯金", code: "bajin9996" },
        // { name: "白银", code: "baiyin9999" },

      ],
      name: "1",
      // 价格盒子
      pricebox: "",
      // 白金
      baiyin9999: [],
      // 钯金
      bajin9996: [],
      // 铂金
      bojin9996: [],
      // 黄金
      huangjin9999: [],
      // 数字实时价格变动
      pricenowbox: [],
      // 为了显示红色还是绿色
      oldpricenowbox: 0,
      // 是否拉取到数据
      havedata: false,
      havedatasecond: false,
      // 当前可视区域的品种
      nowProduct: "",
      // 清楚计时器
      setclear: "",
      timebox: "",
      emitdata: [],
      // 时间戳版本的xy轴
      timelistcenter: [],
      redup: false,
      greendown: false,
      userInfo: "",
      uesrInfos:{},
      returnres: false,
      client: Stomp.client(process.env.VUE_APP_WS),
      isFace:Boolean,
      isAutograph:Boolean,
      isLicense: Boolean,
      faceBackPath:'',//实名认证完成后返回的路由
      error:false
    };
    },
  created() {
       PriceLineChart.getuser().then(res => {
         localStorage.setItem("userinfo", JSON.stringify(res));
        this.userInfo = res.auth;
        this.uesrInfos = res
        this.isFace = res.hasOwnProperty('faceVerify')
        this.isAutograph = res.hasOwnProperty('sign')
        this.isLicense = res.hasOwnProperty('license')
        this.returnres = true;
    })
  },
  mounted() {
    this.connect();
    this.munberprice();
    this.timeDown();
    this.knowprice();


    // 折线图上面的数据实时更新
    // this.setclear = setInterval(() => {
    //   this.munberprice()
    // },1000)
    // 获取ws的链接（暂且注释掉）
    // PriceLineChart.settingSys().then(res=>{
    //   localStorage.setItem('ws',res.wsUrl)
    // })
  },
  methods: {
    goFace(){
      this.$router.push({
        name:'face',
        query:{path:this.faceBackPath}
      })
    },
    buySellLink(item){
      if ((!this.uesrInfos.macroOrder || !this.uesrInfos.forbidden) && item !=="shopping") {
        this.disableModel = true
        return
      }

        if (this.userInfo == 'FAIL' ) {
            //   并未认证
              this.$Loading.show({text:'未实名认证'})

              setTimeout(()=>{
                this.$Loading.close()
                this.$router.push({
                name:'certification',
                // query:{path:'pricing'}
              })
              },1500)
              return false
          }
        if (this.userInfo == 'UNPOST' ) {
            //   并未认证
              this.$Loading.show({text:'未实名认证'})

              setTimeout(()=>{
                this.$Loading.close()
                this.$router.push({
                name:'certification',
                // query:{path:'pricing'}
              })
              },1500)
              return false
          }
        if (this.userInfo == 'POST') {
                // 正在认证
            this.$toast('资料审核中,此功能暂不可用!')
            return false
          }
      if (!this.isLicense) {
        // 暂未营业执照认证
        this.$toast(`未营业执照认证!`)
        setTimeout(()=>{
          this.$router.push({
            name: "license",
            query:{pricing:true}
          });
        }, 1000)
        return false
      }
      if (!this.isAutograph) {
        if (new Date(this.uesrInfos.authPostTime*1000) < new Date("2024-03-26 00:00:00".replace(/-/g,'/'))) {
          // 2024.3.26
          this.$toast(`尊敬的客户您好，为了给您提供更好的服务，本系统全面升级，请重新进入签署界面按流程完成续签！`)
        } else {
          this.$toast(`未按${this.GLOBAL}开户合同进行签名,请签名!`)
        }
        setTimeout(()=>{
          this.$router.push({
            name:'AccountContract',
            query:{pricing:true}
          })
        },1000)
        return false
      }
        /*if (!this.isFace) {
            // this.$toast('请人脸认证!请便后续功能的使用')
            // setTimeout(()=>{
            //     this.$router.push({
            //     name:'face'
            //   })
            //   },1500)
              this.infodetials = "根据相关部门要求，首次交易需要进行人脸认证，认证通过后方可进行交易";
              this.tipTitle = '人脸认证'
              this.faceBackPath = item
              this.nocer = true;
              return false
          }*/
        this.$router.push({
        name:item,
        query:{price:this.pricenowbox.price, initCity: this.productlist[this.thanIndex].name}
      })
    },
      // 底部图片跳转线上回收流程页面
    // onlineRecovery(){
    //    if (this.userInfo == 'UNPOST') {
    //         //   并未认证
    //           this.$Loading.show({text:'未实名认证'})

    //           setTimeout(()=>{
    //             this.$Loading.close()
    //             this.$router.push({
    //             name:'certification'
    //           })
    //           },1500)
    //           return false
    //       }
    //     if (this.userInfo == 'POST') {
    //             // 正在认证
    //         this.$toast('资料审核中,此功能暂不可用!')
    //         return false
    //       }
    //   this.$router.push({
    //     name:'onlineRecovery'
    //   })
    // },
    unzip(b64Data) {
      let strData = atob(b64Data);
      const charData = strData.split("").map(function(x) {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);
      const data = pako.inflate(binData);
      strData = String.fromCharCode.apply(null, new Uint16Array(data));
      return decodeURIComponent(escape(strData));
    },
    onConnected() {
      const topic = "/price/all";
      this.client.subscribe(topic, this.responseCallback, this.onFailed);
    },
    connect() {
      // ---初始化mqtt客户端，并连接mqtt服务
      const headers = {
        login: "username",
        passcode: "password"
      };
      this.client.connect(headers, this.onConnected, this.onFailed);
      this.client.debug = null;
    },
    onFailed(frame) {
      console.log("错误: " + frame);
    },
    responseCallback(frame) {
      const resbody_ = eval("[" + this.unzip(frame.body) + "]")[0];
      const resbody = JSON.parse(JSON.stringify(resbody_));
      this.trade_ = resbody.trade;
      // this.trade_ = true;
      localStorage.setItem("trade_", this.trade_);
    },
    // 获取时间段
    getTimeRang(beginDateStr, endDateStr) {
      const curDate = new Date();
      const year_ = curDate.getFullYear();
      const Month_ = curDate.getMonth() + 1;
      const Day_ = curDate.getDate();
      const timelist = year_ + "/" + Month_ + "/" + Day_;
      const beginDate = new Date(timelist + " " + beginDateStr),
        endDate = new Date(timelist + " " + endDateStr);
      if (curDate >= beginDate && curDate <= endDate) {
        return true;
      }
      return false;
    },
    // 提示停盘
    sellgoldF(price_) {
      if (!this.returnres) {
        return !1;
      }
      if (this.userInfo) {
        if (this.userInfo == "UNPOST") {
          this.infodetials = "您的账号还未认证，暂无此项权限";
          this.nocer = true;
        } else if (this.userInfo == "POST") {
          this.infodetials = "您的认证信息正在审核中，请稍后查看";
          this.nocer = true;
        } else {
          if (this.trade_) {
            this.$router.push({ name: "sellgold", query: { price: price_ } });
          } else {
            this.ceringModel = true;
          }
          // let time = new Date()
          // let day = time.getDay()
          // let hours = time.getHours()
          // let timerang = this.getTimeRang('10:00:00','23:00:00')
          // // 周日或者周六停盘
          // if(day == 0 || day == 6){
          //   this.ceringModel = true
          //   return !1
          // }else if(!timerang){
          //   this.ceringModel = true
          //   return !1
          // }else{
          //   this.$router.push({name:'sellgold',query:{price:price_}})
          // }
        }
      }
    },
    // 分钟转成小时
    ChangeHourMinutestr(str) {
      if (str !== "0" && str !== "" && str !== null) {
        const times =
          (Math.floor(str / 60).toString().length < 2
            ? "0" + Math.floor(str / 60).toString()
            : Math.floor(str / 60).toString()) +
          ":" +
          ((str % 60).toString().length < 2
            ? "0" + (str % 60).toString()
            : (str % 60).toString());
        // console.log(times)
        return times;
      } else {
        return "";
      }
    },
    // 时间计时器
    timeDown() {
      // 现在的时间
      const nowDate = new Date();
      const mon = nowDate.getMonth() + 1;
      const day = nowDate.getDate();
      const hours = nowDate.getHours();
      const min = nowDate.getMinutes();
      const Sec = nowDate.getSeconds();
      this.timebox =
        (mon < 9 ? "0" + mon : mon) +
        "/" +
        (day < 9 ? "0" + day : day) +
        " " +
        hours +
        ":" +
        (min < 9 ? "0" + min : min) +
        ":" +
        (Sec <= 9 ? "0" + Sec : Sec);
      setTimeout(() => {
        this.timeDown();
      }, 1000);
    },
    // 折线图
    knowprice(code) {
      const nowDate = new Date();
      const year = nowDate.getFullYear();
      this.timelistcenter = [];
      this.huangjin9999 = [];
      // key:金属种类 黄金：huangjin9999  白银：baiyin9999  钯金：bajin9996  铂金：bojin9996
      //页面首次刷新默认显示'huangjin9999'数据
      const code_ = code || "huangjin9999";
      PriceLineChart.queryPriceLineChartData(code_).then(res => {
        for (const i in res.data) {
          const monthtime = this.ChangeHourMinutestr(res.data[i].min);
          this.huangjin9999.push({
            time: monthtime || "00:00",
            price: res.data[i].price
          });
          this.timelistcenter.push({
            x: monthtime
              ? new Date(
                  year +
                    "/" +
                    this.timebox.split(" ")[0] +
                    " " +
                    monthtime +
                    ":00"
                ).getTime()
              : new Date(
                  year + "/" + this.timebox.split(" ")[0] + " " + "00:00:00"
                ).getTime(),
            y: +res.data[i].price
          });
        }
        this.havedata = true;
        // 选项卡切换(可以再次点击了)
        // this.isClick = false
      });
    },
    // tab切换
    thanTab(index, code) {
      if (this.thanIndex == index || this.isClick) {
        return false;
      }
      this.tabover = false;
      this.pricenowbox.price = "-";
      // 折线图改变
      this.knowprice(code);
      this.nowProduct = code;
      // 折线图上面的数据
      this.munberprice(code);
      this.isClick = true;
      this.thanIndex = index;
    },
    munberprice(code) {
      // console.log(this.nowProduct);
      // console.log(code);
      //首次加载页面this.nowProduct：false code:false  code_=huangjin9999
      const code_ = this.nowProduct || code || "huangjin9999";
      // console.log(code_);
      // 实时金价
      this.emitdata = [];
      PriceLineChart.queryCurrentPriceLineChart().then(res => {
        const than = this;
        //接口请求成功
        if (res.code == 0) {
          // console.log(res);
          //data:接口返回的数据
          this.pricebox = res.data;
          // console.log(this.pricebox);
          let b;
          for (b in this.pricebox) {
            // console.log(b);
            //处理返回选中金属的实时价格数据  首次加载默认huangjin9999
            if (b == code_) {
              // console.log(this.pricebox[b]);
              than.pricenowbox = this.pricebox[b];
              // console.log('b',b,than.pricenowbox );
              if (than.pricenowbox.price > than.oldpricenowbox) {
                this.redup = true;
                this.greendown = false;
              } else if (than.pricenowbox.price < than.oldpricenowbox) {
                this.greendown = true;
                this.redup = false;
              }
              // 储存旧值
              than.oldpricenowbox = than.pricenowbox.price;
              this.tabover = true;
              this.isClick = false;
              than.emitdata.push(this.pricebox[b]);
            }
          }
          const arr = [];
          const maxPrice = this.pricenowbox.maxPrice;
          const minPrice = this.pricenowbox.minPrice;
          // console.log(this.pricenowbox.todayPrice);
          // console.log('1',this.pricenowbox);
          // console.log(this.pricenowbox.maxPrice);
          // console.log(this.pricenowbox.minPrice);
          const fprice = (maxPrice - minPrice) / 4;

          // 最高点价格、最低点价格
          const topmaxPrice = Number(maxPrice) + Number(fprice) + 1;
          const bottomPrice = Number(minPrice) - Number(fprice) - 1;
          // 每隔一差值
          const dval = (topmaxPrice - bottomPrice) / 5;
          let numberbox = "";
          for (let i = 0; i < 6; i++) {
            if (code_ === "baiyin9999") {
              numberbox = Number(topmaxPrice - i * dval).toFixed(3);
            } else {
              numberbox = Number(topmaxPrice - i * dval).toFixed(2);
            }
            arr.push(+numberbox);
          }
          this.ydataarr = arr.sort();
        }
      }).catch(err=>{

      });
      clearTimeout(this.setclear);
      this.setclear = setInterval(() => {
        this.munberprice();
      }, 3000);
    }
  },
  components: {
    child
  },
  destroyed() {
    clearTimeout(this.setclear);
    this.client.disconnect(function() {
      // 断开连接
      // console.log("See you next time!");
    });
  }
};
</script>
<style lang="scss" scoped="true">
@import "@/assets/styles/index.sass";
::v-deep{
     .van-nav-bar{
        height: 96px;
        .van-nav-bar__content{
        line-height: 96px;
        height: 96px;
         background: #39394d;
        .van-nav-bar__title{
            font-size: 36px;
            color: #f6d0ab;
        }
        }
     }
}
.bg_box {
  background: #f7f7f7;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.gocer {
  font-size: 32px;
  padding: 20px 0;
  margin-top: 30px;
  border-top: 1px solid #ccc;
  color: #FF2D2E;
  text-align: center;
}
.flex-aroundbox {
  margin-top: 38px;
  font-size: 32px;
  border-top: 1px solid #E9E9E9;
  position: relative;
}
.nogocer {
  color: #9c9c9c;
  width: 50%;
  text-align: center;
  padding: 20px 0;
}
.nogocercolor {
  padding: 20px 0;
  color: #FF2D2E !important;
  text-align: center;
  width: 50%;
  &:after {
    content: "";
    background: #E9E9E9;
    width: 2px;
    height: 80px;
    position: absolute;
    left: 50%;
    margin-left: -1px;
    top: 0px;
  }
}
.title {
  font-size: 36px;
  text-align: center;
  margin: 40px 0 25px;
  color: #242424;
  font-weight: 500;
}
.centerbox_dialog {
  padding: 0 30px;
  font-size: 28px;
  font-weight: 400;
  color: #666666;
  text-align: center;
}
::v-deep .van-dialog {
  width: 538px;
}
::v-deep .van-button {
  display: none;
}
.loadigboxdetals {
  height: 140px;
  .van-loading {
    padding-top: 200px;
  }
}
.redfont {
  color: #CC0E0F !important;
}
.greenfont {
  color: #4aba71 !important;
}
.arrowboxImg {
  img {
    width: 6%;
    height: 40px;
  }
}
.arrowbox {
  position: relative;
  left: -10px;
  top: -5px;
  font-size: 40px;
}
.priceboxcenter {
  height: 430px;
}
.centerbox {
  overflow: hidden;
  padding-bottom: 120px;
  background-color: #f5f5f5;
}
.timebox {
  color: #999999;
  font-size: 25px;
}
.flexbox {
  font-size: 28px;
}
.price_marl {
  margin-right: 40px;
}
.price_marb {
  margin-bottom: 30px;
  color: #666666;
}
.nowprice {
  padding: 0 30px 30px;
}
.btnsell {
  color: #fcfafa;
  background: #ffb606;
  border-radius: $btn-border-radius;
  font-size: 36px;
  padding: 20px;
  width: 90%;
  font-weight: bold;
  margin: 50px auto 0;
}
.stepbox {
  margin-top: -90px;
  text-align: center;
  color: #333333;
  font-size: 26px;
  .iconbox {
    margin-bottom: 20px;
    width: 90px;
    height: 90px;
  }
}
.Process {
  margin: 0 30px;
  .fontdetails {
    flex: 2;
    color: #b3b3b3;
    font-size: 24px;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 20px;
    padding-bottom: 20px;
    margin-left: 20px;
    padding-right: 20px;
    .titlebox {
      font-size: 30px;
      margin-bottom: 10px;
      color: #333333;
    }
  }
  .noborder {
    border: 0;
  }
  .detailsbox {
    padding-top: 20px;
    border-radius: 12px;
    box-shadow: 0 0 10px 6px #e1e1e1;
  }
  .flex {
    padding-left: 20px;
    .imgbox {
      // padding-left: 20px;
      width: 66px;
      height: 73px;
    }
  }
}
.backstep {
  font-weight: bold;
  font-size: 32px;
  color: #343434;
  margin: 40px 0 30px;
  b {
    width: 10px;
    height: 40px;
    vertical-align: middle;
    background: #ffb606;
    border-radius: 8px;
    display: inline-block;
    margin-right: 30px;
    // border-left: 8px solid #FFB606;
  }
}
.tab_div {
  padding-top: 20px;
  color: #8F8F9F;
  font-size: 28px;
  position: relative;
  .active {
    position: relative;
    font-weight: 600;
  font-size: 32px;

    color: #39394D;
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      margin-left: -34px;
      bottom: -26px;
      width: 70px;
      height: 5px;
      background-color: #39394D;
    }
  }
}
.bottombox {
  position: relative;
  top: -150px;
}
.citybox {
  color: #cccccc;
  font-size: 40px;
  padding: 0 30px;
}
.drepcolor {
  color: #292929;
}
::v-deep .van-picker__confirm {
  font-size: 32px;
}
::v-deep .van-picker__columns .van-picker-column:nth-child(2) {
  display: none;
}
::v-deep .van-picker-column__item {
  font-size: 30px;
}
::v-deep .el-input__inner {
  border: 0;
  height: auto;
  font-size: 40px;
  padding: 0;
}
::v-deep .el-input__inner::placeholder {
  color: #cccccc;
}
.weightbox {
  padding: 0 30px 30px;
  border-bottom: 1px solid #e0e0e0;
  .unit {
    color: #ccc;
    font-size: 40px;
  }
}
.moneybox {
  padding: 0 30px;
  font-size: 40px;
  color: #cccccc;
  b {
    font-weight: normal;
  }
}
.title_box {
  font-size: 28px;
  color: #292929;
  margin: 0 30px;
  color: #292929;
  padding: 30px 0;
}
.btnbox {
  width: 690px;
  background: #f2aa2e;
  color: #000000;
  margin: 0 auto;
  text-align: center;
  font-size: 36px;
  line-height: 88px;
  border-radius: 8px;
}
.tipBox {
  padding: 40px 30px 70px;
  color: #ccc;
  font-size: 24px;
  div {
    margin-bottom: 8px;
  }
}
.boxShadow{
  box-shadow: 0px 4px 10px 0px rgba(57,57,77,0.5);
  margin: 30px auto;
   width: 690px;
   border-radius: 16px;
  background: #39394d;
}
.yellowbox {
  text-align: center;
  width: 690px;
  height: 100px;
  margin: 0 auto;
  background-color: #fff;
}
.boxgoldprice {
  min-height: 600px;
  width: 690px;
  background: #fff;
  border-radius: 8px;
  position: relative;
  top: 0px;
  margin: 0 auto;
  padding: 30px 0 40px;
  text-align: center;
   box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
  h2 {
    color: #999999;
    font-weight: normal;
    font-size: 30px;
  }
  .pricebox {
    font-weight: bold;
    font-size: 44px;
    color: #fa534c;
    margin-bottom: 20px;
  }
}
.head{
  height: 100px;
  width: 100%;
  color: #fff;
  text-align: center;
  line-height: 100px;
  font-size: 32px;
  background-color: #FF2D2E;
}
/*.sell{
  background: url('~@/assets/images/pic_price_sell@2x.png') no-repeat;

}
.buy{
  background: url('~@/assets/images/pic_price_buy@2x.png') no-repeat;
}*/
.sell,
.buy{
  background-size: 101% 104%;
  padding: 40px;
  height: 280px;
  h3{
      margin-bottom: 16px;
    font-weight: 600;
    color: #f6d0ab;
      font-size: 48px;

  }
  h4{
    color: #ffe8da;
    font-size: 28px;
    font-weight: 400;
  }
}
.manual{
  height: 214px;
  width: 100%;
  background: url('~@/assets/images/pic_banner_sell@2x.png') no-repeat;
  background-size: 101% 104%;
}
::v-deep{
  .van-dialog__header{
      font-size: 36px;
      font-weight: 500;
      color: #242424;
      line-height: 36px;
  }
}
.flex_between{
  display: flex;
  justify-content: space-between;
}
.newPricing {
  background: #ffffff;
  margin: 30px auto;
  width: 690px;
  .newPricingTitle {
    padding: 32px;
    font-size: 36px;
    color: #39394D;
    font-weight: 500;
  }
  .newPricingContent {
    padding: 0 32px 32px;
    .newPricingBoxCommon {
      width: 334px;
      height: 166px;
      background: linear-gradient(270deg, #FDE3C2 0%, #FEF2D9 100%);
      border-radius: 6px;
      padding: 20px 0 0 24px;
      margin-bottom: 18px;
      margin-right: 18px;
      h3{
        margin-bottom: 16px;
        font-weight: 600;
        color: #39394D;
        font-size: 36px;
      }
      h4 {
        width: 200px;
        color: #8F8F9F;
        font-size: 24px;
        font-weight: 400;
      }
      img {
        margin-left: -16px;
      }
    }
  }
  .newPricingBoxShop {
    background: url('~@/assets/images/icon_pricing_shopping_bg.png') no-repeat;
    width: 100%;
    height: 350px;
    background-size: 101% 104%;
    padding: 20px 0 0 24px;
    h3{
      margin-bottom: 16px;
      font-weight: 600;
      color: #39394D;
      font-size: 36px;
    }
    h4 {
      width: 200px;
      color: #8F8F9F;
      font-size: 24px;
      font-weight: 400;
    }
    img {
      margin-top: -16px;
    }
  }
}
</style>
