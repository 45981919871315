<template>
  <div>
    <div id="goldprice"></div>
  </div>
</template>
<style lang="scss" scoped="true">
#goldprice {
  height: 450px;
}

</style>
<script>
import HighCharts from "highcharts";
export default {
  data() {
    return {
      id: "goldprice",
      databox: [],
      seriesarr: [],
      arrsdata: []
    };
  },
  // 接受父组件的值
  props: {
    ydata: Array,
    data_box: Array,
    inputName: Object,
    required: true,
    datalist: Array,
    secondprice: Array
  },
  mounted() {
    // setInterval(() => {
    //   console.log(this.secondprice[0].price)
    // }, 1000);
    // setTimeout(() => {
    //   this.datalist.push({price: "400.65",time: "15:50"})
    //   console.log("123")
    // }, 5000);
    this.initLoadData();
  },
  watch: {
    secondprice: function(val) {
      if (val[0]) {
        // console.log(val[0].price)
        // this.initLoadData()
        // console.log(this.seriesarr.addPoint)
        // this.seriesarr.xAxis.categories.push('17:40')
        // this.seriesarr.addPoint('400')
      }
    },
    datalist: function(val) {
      this.initLoadData();
    }
  },
  methods: {
    changeTwoDecimal_f(x) {
      var f_x = parseFloat(x);
      if (isNaN(f_x)) {
        return 0;
      }
      var f_x = Math.round(x * 100) / 100;
      let s_x = f_x.toString();
      let pos_decimal = s_x.indexOf(".");
      if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += ".";
      }
      while (s_x.length <= pos_decimal + 2) {
        s_x += "0";
      }
      return s_x;
    },
    initLoadData() {
      const datamunber = this.datalist;
      const filterdata = [];
      const timelist = [];
      const categories_ = [];
      for (const b in datamunber) {
        // filterdata.push({x:datamunber[b].time,y:+datamunber[b].price})
        categories_.push(datamunber[b].time);
        filterdata.push(+datamunber[b].price);
      }
      this.initPirce(filterdata, categories_);
    },
    initPirce(filterdata, categories_) {
      const than = this;
      HighCharts.chart(this.id, {
        chart: {
          zoomType: "x",
          events: {
            load: function() {
              // setInterval(()=>{
              // this.series[0].xAxis.categories.push('17:40')
              // than.series[0].points.refresh()
              // },1000)
              than.seriesarr = this.series[0];
            }
          }
        },
        title: {
          text: ""
        },
        subtitle: {
          text: document.ontouchstart === undefined ? "" : ""
        },
        credits: {
          enabled: false
        },
        xAxis: {
          // gridLineColor: '#e8e8e8', gridLineWidth: 1 ,
          boundaryGap: false,
          type: "datetime",
          categories: categories_,
          tickInterval: 100,
          dateTimeLabelFormats: {
            hour: "%H:%M"
          }
        },
        tooltip: {
          backgroundColor: "rgba(77,77,77,0.8)",
          borderColor: "#4d4d4d",
          style: {
            // 文字内容相关样式
            color: "#fff",
            fontFamily: "Courir new",
            redDian:{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                backgroundColor: "red",
              }
          },
          useHTML: true,
          // {series.name}:
          headerFormat:'时间&nbsp;: &nbsp;{point.x}<br>',
          pointFormat: '价格&nbsp;: &nbsp;{point.y}',

            // '<tr style="background: red!important"><td ></td>' +
            // '<td style="text-align: right">价格: <span>{point.y}</span></td></tr>'
          // dateTimeLabelFormats: {
          //   millisecond: '%H:%M:%S.%L',
          //   second: '%H:%M:%S',
          //   minute: '%H:%M',
          //   hour: '%H:%M',
          //   day: '%Y-%m-%d',
          //   week: '%m-%d',
          //   month: '%Y-%m',
          //   year: '%Y'
          // }
        },
        yAxis: {
          tickPositions: this.ydata,
          title: {
            text: ""
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {

        },
        series: [
          {
            color: "#f6d0ab", // 改变线条颜色
            type: "spline",
            name: "黄金9999",
            data: filterdata
            // data:this.data_box
          }
        ]
      });
    }
  }
};
</script>
